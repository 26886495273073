<template>
  <div class="upw-list">
    <div class="upw-list__heading">
      <search-bar
        placeholder="Search by name, ID or admin email..."
        @search="onSearch"
      />
      <filters :menu="menu" @filterChange="setFilterObj" />
      <LxpButton variant="secondary1" @click="onAddUser"
        ><img
          src="@/assets/images/partner/user-permissions/add.svg"
          alt="add"
          class="mr-2"
        />Add User</LxpButton
      >
    </div>
    <div class="upw-list__pills">
      <filter-pills :filters="filter" v-if="showFilterPills" />
    </div>
    <p class="cw-main__search-result" v-if="search !== ''">
      Showing results for <span>{{ search }}</span>
    </p>
    <div class="upw-list__main">
      <LxpPaginationWrapper
        ref="userlistwrapper"
        :dataFetchURL="dataFetchURL"
        :authToken="`Bearer ${$keycloak.token}`"
        nextPageLabel="next"
        :pageSizeValue="20"
        showNumberPagination
        @count="updateCount"
      >
        <template v-slot:emptyState>
          <div class="cw-main__empty">
            <div class="cw-main__results--empty">
              <img
                :src="
                  hasSearchOrFilter
                    ? require('@/assets/images/partner/shared/search-result-empty.svg')
                    : require('@/assets/images/partner/user-permissions/add-user.svg')
                "
                alt="empty"
                class="cw-main__empty--img"
              />
              <list-empty-state
                :search="search"
                :hasFilters="showFilterPills"
                v-if="hasSearchOrFilter"
              />
              <div class="cw-main__results--empty-div" v-else>
                <p>Add your first team members.</p>
                <span>You have not added any users to your Studio. </span>
                <span>Add user to help you manage stuff around.</span>
                <LxpButton variant="secondary1" @click="onAddUser"
                  ><img
                    src="@/assets/images/partner/user-permissions/add.svg"
                    alt="add"
                    class="mr-2"
                  />Add User</LxpButton
                >
              </div>
            </div>
          </div>
        </template>
        <template v-slot:data="slotProps">
          <LxpTable
            :tableData="getUserData(slotProps.data)"
            :tableHeader="tableHeader"
            :hasPagination="false"
            variant="secondary1"
            @onItemClick="onItemClick"
            @sort="onSort"
            v-if="slotProps.data.length > 0"
          >
            <template v-slot:footerContent>
              <p>{{ count }} results</p>
            </template>
          </LxpTable>
        </template>
      </LxpPaginationWrapper>
    </div>
    <add-user :show.sync="showAddUser" @addUser="onCreateNewUser" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LxpButton, LxpTable, LxpPaginationWrapper } from "didactica";
// components
import SearchBar from "../Shared/SearchBar.vue";
import AddUser from "../Shared/AddUser.vue";
import Filters from "../Shared/Filters.vue";
import FilterPills from "../Shared/FilterPills.vue";
import ListEmptyState from "../Shared/ListEmptyState.vue";
export default {
  components: {
    LxpButton,
    LxpTable,
    LxpPaginationWrapper,
    SearchBar,
    AddUser,
    Filters,
    FilterPills,
    ListEmptyState
  },
  data() {
    return {
      tableHeader: [
        {
          key: "name",
          name: "Name",
          sortable: true,
          tdStyle: "font-weight: 500"
        },
        {
          key: "role",
          name: "Role",
          sortable: true,
          tdStyle: "text-transform: capitalize"
        },
        {
          key: "email",
          name: `Email`,
          thStyle: `width: 25%`,
          sortable: true
        },
        {
          key: "username",
          name: "Username",
          sortable: true
        },
        {
          key: "status",
          name: "Status",
          sortable: true
        },
        {
          key: "actions",
          name: "",
          tdStyle: "text-align:center",
          sortable: false
        }
      ],
      showAddUser: false,
      search: "",
      count: 0,
      filter: {
        status: "",
        role: ""
      },
      ordering: "",
      menu: [
        {
          heading: "By Status",
          id: "status",
          selected: "",
          collapse: false,
          items: [
            { id: "2", value: true, text: "Active" },
            { id: "3", value: false, text: "Blocked" }
          ],
          type: "radio"
        },
        {
          heading: "By Role",
          id: "role",
          selected: "",
          collapse: false,
          items: [
            { id: "2", value: "admin", text: "Admin" },
            { id: "3", value: "staff", text: "Staff" }
          ],
          type: "radio"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["allConfig"]),
    dataFetchURL() {
      return `${this.allConfig.baseURL}/gateway/users/?search=${this.search}&filtering=${this.roleFilter}&is_active=${this.statusFilter}&ordering=${this.ordering}`;
    },
    showFilterPills() {
      return this.filter.status !== "" || this.filter.role !== "";
    },
    statusFilter() {
      return this.filter.status ? this.filter.status.value : "";
    },
    roleFilter() {
      return this.filter.role ? this.filter.role.value : "";
    },
    hasSearchOrFilter() {
      return this.search !== "" || this.showFilterPills;
    }
  },
  methods: {
    getUserData(data) {
      data.forEach(d => {
        if (d.first_name !== "" || d.last_name !== "") {
          d.name = `${d.first_name} ${d.last_name}`;
        } else {
          d.name = "-";
        }
        d.actions = `<div class="upw-list__edit">
          <img src="${require("@/assets/images/partner/user-permissions/edit.svg")}" alt="edit"/>
          </div>`;
        d.status = d.is_active ? "Active" : "Blocked";
      });
      return data;
    },
    onItemClick(item) {
      if (item.key === "actions") {
        this.$store.commit("SET_CURRENT_USER", item.data);
        this.$router.push({
          path: `/partner/user-permissions/${item.data.id}/edit`
        });
      }
    },
    onAddUser() {
      this.showAddUser = true;
    },
    updateCount(c) {
      this.count = c;
    },
    setFilterObj() {
      this.menu.forEach(fm => {
        if (fm.selected !== "") {
          this.filter[fm.id] = fm.items.find(
            item => item.value === fm.selected
          );
        } else {
          this.filter[fm.id] = "";
        }
      });
    },
    onCreateNewUser() {
      this.$refs.userlistwrapper.reload();
    },
    onSort(item) {
      let key = "";
      if (item.key === "name") {
        key = "first_name";
      } else if (item.key === "status") {
        key = "is_active";
      } else {
        key = item.key;
      }
      this.ordering = item.sort ? `-${key}` : key;
    },
    onSearch(search) {
      this.search = search;
    }
  }
};
</script>

<style lang="scss">
.upw-list {
  .upw-list__heading {
    margin-bottom: 16px;
    @include horizontal-space-between;
    .partner-search-bar {
      flex-grow: 1;
    }
  }
  @include searchResult;
  .upw-list__main {
    .upw-list__edit {
      cursor: pointer;
      padding: 8px;
      background: $brand-secondary1-50;
      border-radius: 100px;
      display: inline-flex;
      visibility: hidden;
    }
    tr {
      &:hover {
        .upw-list__edit {
          visibility: visible;
        }
      }
    }
    @include empty-state;
    .cw-main__empty {
      background: $brand-neutral-0;
      border-radius: 16px;
      border: 1px solid $brand-secondary1-50;
      width: 100%;
      .cw-main__empty--img {
        margin-bottom: 16px;
      }
      button {
        margin-top: 16px;
      }
    }
  }
}
</style>
